.topicContainer {
  margin-bottom: 23px;
}
.topic {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  color: #000000;
  text-transform: capitalize;
  margin: 0;
}
