.itemContainer {
  padding-bottom: 29px;
  border-bottom: 1px solid rgba(56, 56, 56, 0.15);
  margin-bottom: 33px;
}
.articleImageContainer {
  width: 134px;
  height: 134px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.articletitleContainer {
  max-width: calc(100% - 50px);
}
.articlePublishedDate {
  font-weight: 400;
  font-size: 15px;
  line-height: 148.9%;
  color: #959393;
  text-transform: capitalize;
  margin-bottom: 13px;
}
.articleTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 106.9%;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
}
.articlContentContainer {
  margin-top: 10px;
}
.articleContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  color: var(--secondaryColor3);
  margin: 0;
}
.bookmarkRemoveIcon {
  cursor: pointer;
}
@media (max-width: 767px) {
  .articleTitle {
    font-size: 20px;
  }
  .articleImageContainer {
    height: 114px;
    border-radius: 6px;
  }
  .itemContainer {
    border: 0;
  }
  .articletitleContainer {
    padding-right: 0;
  }
  .articleImageContainerParent {
    padding-right: 2px;
  }
  .itemContainer {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .articleImageContainer {
    width: 120px;
    height: 84px;
  }
  .articleTitle {
    font-weight: 600;
    font-size: 15px;
  }
  .articlePublishedDate {
    font-weight: 500;
    color: var(--secondaryColor1);
    margin-top: 8px;
    font-size: 13px;
  }
  .itemContainer {
    margin-bottom: 21px;
  }
  .bookmarkRemoveIcon {
    width: 19px;
  }
}
