.buttonsContainer {
  margin: 5px 0 110px;
}
.declineButton,
.acceptButton {
  outline: none !important;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.9%;
  color: #000000;
}
.declineButton {
  border: 1px solid red;
  border-radius: 5px;
  width: 153px;
  height: 55px;
  background: white;
  color: red;
}
.acceptButton {
  background: var(--primaryColor1);
  border-radius: 5px;
  border: none;
  width: 252px;
  height: 55px;
}
.acceptButtonIcon {
  position: relative;
  top: 3px;
  margin-right: 11px;
}
.acceptButtonContent {
  position: relative;
  top: -4px;
}
@media (max-width: 575px) {
  .declineButton,
  .acceptButton {
    width: 100%;
  }
  .acceptButton {
    margin-top: 20px;
  }
  .buttonsContainer {
    margin: 55px 0 100px;
  }
}
