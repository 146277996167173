.titleIconContainer {
  margin-right: 20px;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 29px;
  line-height: 106.9%;
  text-transform: uppercase;
  color: #000000;
  margin: auto;
}
.titleContainer {
  margin-bottom: 34px;
}
@media (max-width: 575px) {
  .title {
    font-size: 26px;
  }
}
