.inviteIconContainer {
  display: flex;
}
.inviteIconContainer img {
  margin: auto 10px auto 0;
}
.inviteText {
  margin: auto 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 148.9%;
  color: var(--primaryColor2);
  text-transform: capitalize;
}
.inviteButton {
  padding: 7px 13px 7px 9px;
  border: 1px solid var(--primaryColor2);
  border-radius: 5px;
  cursor: pointer;
}
