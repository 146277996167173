.buttonContainer {
  margin-bottom: 100px;
}
.actionButton {
  background-color: var(--primaryColor1);
  /* border: 1px solid var(--primaryColor2); */
  border: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: black;
  text-transform: capitalize;
  margin-left: 30px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabledButton {
  background-color: #e1e1e1;
  cursor: not-allowed !important;
  border: none;
  color: black;
}
