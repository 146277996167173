.footer {
  background: black;
  padding: 69px 0 50px;
}
.footerLink {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  color: #ffffff;
  text-transform: capitalize;
  text-decoration: none;
  margin-bottom: 20px;
  cursor: pointer;
  display: flex;
}
.footerNavLink {
  text-decoration: none;
}
.footerLinkSet {
  display: flex;
  flex-direction: column;
}
.singleFooterLinkContainer {
  display: flex;
  justify-content: flex-start;
}
.footerLinksContainer {
  margin-bottom: 60px;
}
.followUs {
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 106.9%;
  color: #ffffff;
  text-transform: capitalize;
}
.footerIcon {
  margin-right: 20px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .footer {
    padding: 69px 0 130px;
  }
}

@media (max-width: 767px) {
  .footerLink {
    text-align: center;
    display: block;
  }
  .singleFooterLinkContainer {
    justify-content: center;
  }
  .followUs {
    text-align: center;
    margin-bottom: 20px;
  }
}
