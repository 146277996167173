@media screen and (min-width: 750px) {
  .centerDiv {
    width: 690px;
    max-width: 690px;
  }
}
.centerDiv {
  width: 100%;
  max-width: 690px;
  margin: 80px auto 0;
}
.titleBox {
  display: flex;
}
.title {
  font-weight: 500;
  font-size: 30px;
  line-height: 106.9%;
  color: #000000;
  margin-bottom: 27px;
}
.icon {
  height: 26px;
  color: #3caac4;
  padding-right: 9px;
  margin-top: 5.5px;
}
.taglineBox {
  width: 100%;
}
.titleTagline {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 148.9%;
  display: flex;
  align-items: center;
  color: var(--lightGray);
  margin-bottom: 46px;
}
.previouslySelectedInterestsBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px;
  margin-bottom: 32px;
}
@media screen and (min-width: 550px) {
  .bottomBox {
    width: 543px;
    max-width: 543px;
  }
}
.bottomBox {
  width: 100%;
  max-width: 543px;
}
.line {
  width: 100%;
  height: 1px;
  opacity: 0.2;
  background-color: var(--lightGray);
  margin-top: 32px;
  margin-bottom: 52px;
}
.searchBar {
  box-sizing: border-box;
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  margin-bottom: 18px;
  outline: none !important;
  padding: 0 16px;
}
.searchBar::placeholder {
  font-family: "Baloo Da 2";
  font-style: normal;
  font-weight: 400;
  font-size: 19px;
  line-height: 148.9%;
  color: var(--lightGray);
  padding-top: 10px;
  padding-bottom: 10px;
  outline: none;
}
.searchBarBtnBox {
  height: 48px;
}
.searchBarBtn {
  background-color: white;
  border: none;
  width: 100%;
  height: 48px;
}
.searchBarText {
  font-weight: 700;
  font-size: 19px;
  line-height: 148.9%;
  text-align: center;
  text-transform: uppercase;
  color: #3caac4;
  margin: 10px 0;
}
@media screen and (min-width: 500px) {
  .searchedInterestsBox {
    margin: -12.5px -6.5px;
  }
}
.searchedInterestsBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -5px -5px;
}
.submitBtnDiv {
  margin-top: 87px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 299px;
}
.submitBtn {
  width: 215px;
  height: 63px;
  background: #ffc942;
  border-radius: 50px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.submitBtnText {
  margin: 22px 0;
  font-weight: 400;
  font-size: 19px;
  line-height: 148.9%;
  color: #000000;
  text-transform: capitalize;
}
