.notificationListContainer {
  margin: 30px 0 150px;
}
@media (max-width: 1399px) {
  .notificationListContainer {
    margin: 30px 0 90px;
  }
}
@media (max-width: 991px) {
  .notificationListContainer {
    margin: 30px 0 60px;
  }
}
