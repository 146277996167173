.navBarContainer {
  background: white;
  box-shadow: 0px -9px 21px -3px rgba(0, 0, 0, 0.25);
  border-radius: 83px;
  padding: 18px 25px 15px;
  position: fixed;
  bottom: 10px;
  right: 8px;
  width: calc(100% - 16px);
  z-index: 1020;
}
