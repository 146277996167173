.customModalBody {
  padding: 0;
}
@media (min-width: 620px) {
  .customAcceptModal {
    width: 600px;
    max-width: 600px;
  }
}
@media (min-width: 730px) {
  .customAcceptModal {
    width: 700px;
    max-width: 700px;
  }
}
.modalCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  padding-right: 25px;
  margin-bottom: 9px;
}
.modalCloseButton {
  outline: none !important;
  box-shadow: none !important;
  color: var(--lightGray);
  font-weight: lighter;
  cursor: pointer;
}
.modalBodyContainer {
  padding: 0 55px;
}
.modalTitle {
  color: #000;
  font-size: 27px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.9%; /* 31.001px */
  text-align: center;
  margin: 20px 0 50px;
}
.description {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 149.9%; /* 28.481px */
  letter-spacing: 0.19px;
  margin-bottom: 56px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
}
.radionButtonsContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}
.draftRadioButton {
  margin-right: 130px;
}
.radioButtonInputField {
  margin-right: 5px;
}
.underReviewButton,
.revertToDraftButton {
  height: 60px;
  width: 250px;
  border: none;
  border-radius: 60px;
  margin-bottom: 59px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  outline: none !important;
  font-weight: 400;
}
.revertToDraftButton {
  border: 2px solid var(--primaryColor1);
  background-color: white;
  color: var(--secondaryColor3);
}
.underReviewButton {
  margin-left: 20px;
  background-color: var(--primaryColor1);
  order: 1;
}
.returnIconContainer {
  margin-right: 10px;
}
.returnIcon {
  position: relative;
  top: 4px;
}
@media (max-width: 729px) {
  .modalBodyContainer {
    padding: 0 38px;
  }
}
@media (max-width: 619px) {
  .buttonContainer {
    width: 100%;
    flex-direction: column;
  }
  .underReviewButton,
  .revertToDraftButton {
    width: 100%;
  }
  .revertToDraftButton {
    order: 1;
    margin-bottom: 20px;
  }
  .underReviewButton {
    margin-left: 0;
    order: 2;
  }
  .description {
    margin-bottom: 36px;
  }
}
@media (max-width: 460px) {
  .modalBodyContainer {
    padding: 0 25px;
  }
}
