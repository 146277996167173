.itemContainer {
  padding-bottom: 29px;
  border-bottom: 1px solid rgba(56, 56, 56, 0.15);
  margin-bottom: 33px;
}
.articleImageContainer {
  width: 134px;
  height: 134px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.articletitleContainer {
  display: flex;
}
.articlePublishedDate {
  font-weight: 400;
  font-size: 15px;
  line-height: 148.9%;
  color: #959393;
  text-transform: capitalize;
  margin-bottom: 13px;
}
.articleTitle {
  font-weight: 500;
  font-size: 22px;
  line-height: 106.9%;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
}
.primaryButtonSet {
  display: flex;
}
.secondaryButtonSet {
  display: none;
  justify-content: flex-end;
}
.articlContentContainer {
  margin-top: 10px;
}
.articleContent {
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  color: var(--secondaryColor3);
  margin: 0;
}
.dropdownItem:active {
  background-color: var(--primaryColor1);
}
.disabledDropdownItem:active {
  background-color: var(--secondaryColor4);
}
.customToggle {
  color: black;
  background-color: white;
  border: none;
  padding: 0;
}
.customToggle a {
  color: black !important;
}
.customMenu {
  left: -158px !important;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  text-transform: capitalize;
  min-width: 100%;
  width: 180px;
}
.customMenu ul {
  margin: 0;
}
.customMenu a {
  color: #959393 !important;
}
.customMenu a.disabledDropdownItem {
  color: #cbcbcb !important;
}
@media (max-width: 1799px) and (min-width: 1400px) {
  .secondaryButtonSet {
    display: flex;
  }
  .primaryButtonSet {
    display: none;
  }
  .articleTitle {
    font-size: 20px;
  }
}
@media (max-width: 1599px) {
  .articleTitle {
    font-size: 20px;
  }
}
@media (max-width: 1199px) {
  .secondaryButtonSet {
    display: flex;
  }
  .primaryButtonSet {
    display: none;
  }
}
@media (min-width: 768px) {
  .dropdownContainer {
    margin-left: 21px;
  }
}
@media (max-width: 767px) {
  .articletitleContainer {
    max-width: 100%;
  }
  .articlePublishedDate {
    font-weight: 500;
    color: var(--secondaryColor1);
    margin-top: 8px;
  }
  .articleImageContainer {
    height: 114px;
    border-radius: 6px;
  }
  .itemContainer {
    border: 0;
  }
  .articletitleContainer {
    padding-right: 0;
  }
  .articleImageContainerParent {
    padding-right: 2px;
  }
  .itemContainer {
    padding-bottom: 0;
  }
}
@media (max-width: 575px) {
  .articleImageContainer {
    width: 120px;
    height: 84px;
  }
  .articleTitle {
    font-weight: 600;
    font-size: 15px;
  }
  .articlePublishedDate {
    font-size: 13px;
  }
  .itemContainer {
    margin-bottom: 21px;
  }
}
