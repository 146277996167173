.indicatorItem {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(167, 167, 167, 0.59);
}
.activeIndicatorItem {
  background-color: var(--primaryColor2);
  border: none;
}
.icon {
  width: 25px;
}
.status {
  color: #a6a3a3;
  text-align: center;
  font-weight: 400;
  line-height: 148.9%; /* 23.824px */
  position: absolute;
  font-size: 16px;
  bottom: -40px;
  margin: 0;
  white-space: nowrap;
  width: 136px;
  left: -39px;
  text-transform: capitalize;
}
@media (max-width: 767px) {
  .indicatorItem {
    height: 50px;
    width: 50px;
  }
  .status {
    font-size: 13px;
    bottom: -28px;
    width: 110px;
    left: -30px;
  }
  .icon {
    width: 22px;
  }
}
