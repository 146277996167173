.title {
  font-weight: 700;
  font-size: 23px;
  line-height: 106.9%;
  text-transform: uppercase;
  color: var(--secondaryColor3);
  margin-bottom: 34px;
}
@media (max-width: 767px) {
  .title {
    font-size: 24px;
    text-transform: unset;
  }
}
