.title {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 106.9%;
  text-align: center;
  color: var(--secondaryColor1);
  text-transform: capitalize;
  margin-bottom: 30px;
}
@media (max-width: 1399px) {
  .title {
    font-size: 24px;
  }
}
@media (max-width: 1199px) {
  .title {
    font-size: 30px;
  }
}
