.itemContainer {
  border: 3px solid #d9d9d9;
  border-radius: 5px;
  padding: 23px 0 42px;
  margin-bottom: 24px;
}
.iconContainer {
  display: flex;
  min-height: 52px;
}
.icon {
  margin: auto;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 148.9%;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 0;
  margin-top: 25px;
}

@media (max-width: 575px) {
  .title {
    font-size: 19px;
    margin-top: 10px;
  }
}
