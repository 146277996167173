.itemMainContainer {
  padding: 0 7px;
}
.itemContainer {
  border: 1px solid var(--secondaryColor4);
  border-radius: 35px;
  display: flex;
  padding: 8px 28px;
  margin-bottom: 15px;
}
.itemName {
  margin: auto;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 148.9%;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
}
