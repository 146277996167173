.markAllAsReadButton {
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 148.9%;
  text-decoration-line: underline;
  color: var(--primaryColor2);
}
.tabTitle {
  margin-bottom: 0;
}
@media (max-width: 575px) {
  .markAllAsReadButton {
    font-size: 16px;
  }
}
