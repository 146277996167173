.actionButton {
  border: 2px solid #3d6072;
  border-radius: 50px;
  outline: none !important;
  width: 100%;
  height: 49px;
  background: white;
  font-weight: 500;
  font-size: 16px;
  line-height: 148.9%;
  color: var(--secondaryColor1);
  text-transform: capitalize;
  margin-top: 15px;
}
@media (max-width: 1399px) {
  .actionButton {
    font-size: 14px;
  }
}
@media (max-width: 1199px) {
  .actionButton {
    width: 260px;
  }
}
