.componentDiv {
  box-sizing: border-box;
  display: flex;
  background: rgba(60, 170, 196, 0.06);
  border: 1px solid #3caac4;
  border-radius: 58px;
  height: 45px;
  padding: 10px 20px;
  margin: 5px 5px;
  margin-bottom: 12.5px;
}
.componentText {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 148.9%;
  align-items: center;
  text-transform: capitalize;
  color: #000000;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.icon {
  font-weight: 400;
  font-size: 17px;
  line-height: 148.9%;
  margin-left: 16px;
  cursor: pointer;
}
