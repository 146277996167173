@media (min-width: 992px) {
  .valueBox {
    max-width: 417px;
  }
}
.valueBox {
  width: 100%;
  height: 100%;
  background-color: white;
  padding: 49px 32px;
  border-radius: 6px;
}
.titleDiv {
  display: flex;
}
.icon {
  color: var(--primaryColor1);
  padding-right: 16.5px;
  font-weight: 600;
  font-size: 27px;
  line-height: 118.9%;
  padding-bottom: 41px;
}
.title {
  font-weight: 600;
  font-size: 27px;
  line-height: 118.9%;
  text-transform: capitalize;
  color: var(--primaryColor2);
}
.content {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 168.9%;
  color: var(--lightGray);
}
