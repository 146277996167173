@media screen and (min-width: 650px) {
  .centerDiv {
    width: 554px;
    max-width: 554px;
  }
}
.centerDiv {
  width: 90%;
  max-width: 554px;
  margin: 80px auto 0;
}
.title {
  text-align: center;
  /* margin-top: 79px; */
  margin-bottom: 27px;
  font-weight: 500;
  font-size: 33px;
  line-height: 106.9%;
  color: #000000;
}
.taglineText {
  margin-bottom: 49px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 148.9%;
  color: var(--lightGray);
}
.interestSelectBox {
  display: flex;
  flex-wrap: wrap;
  margin: -9px -7px;
  padding-bottom: 52px;
}
.line {
  background-color: var(--lightGray);
  opacity: 0.2;
  width: 100%;
  height: 1px;
  margin-bottom: 52px;
}
@media screen and (min-width: 500px) {
  .searchedInterestsBox {
    margin: -9px -7px;
  }
}
.searchedInterestsBox {
  display: flex;
  margin: -9px -5px;
  flex-wrap: wrap;
}
.submitBtnDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 62px;
  margin-bottom: 70px;
}
.submitBtn {
  width: 265px;
  height: 72px;
  background: var(--primaryColor1);
  border-radius: 60px;
  border: none;
}
.submitBtnText {
  margin: 22px 0;
  font-weight: 500;
  font-size: 19px;
  line-height: 148.9%;
  text-transform: capitalize;
  text-align: center;
  color: #171e26;
}
