.messageContainer {
  background-color: var(--secondaryColor1);
  padding: 17px 0;
}
.message {
  color: white;
  text-align: center;
  margin: 0;
  font-size: 14px;
}
.message span {
  font-size: 16px;
}
