.tabContainer {
  background: var(--secondaryColor2);
  font-size: 10px;
  border-radius: 40px;
  display: flex;
  width: 87px;
  padding: 2px 0;
}
.tagText {
  margin: auto;
  color: white;
  text-transform: capitalize;
}
