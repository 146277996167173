.rangeValueContainer {
  margin-bottom: 21px;
}
.label {
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  color: var(--secondaryColor1);
}
.value {
  font-weight: 400;
  font-size: 17px;
  line-height: 106.9%;
  color: var(--secondaryColor1);
}
.sliderContainer {
  width: 100%;
  position: relative;
}
.SelectedArea {
  background: var(--primaryColor2);
  border-radius: 74px 0 0 74px;
  height: 7px;
  position: absolute;
  left: 0;
  top: 10px;
}
.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 7px;
  border-radius: 74px;
  background: rgba(60, 170, 196, 0.18);
  z-index: 10;
  position: relative;
}
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #3caac4;
  cursor: pointer;
}
