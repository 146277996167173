.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  opacity: 1;
  z-index: 1000;
}
.loader {
  transform-origin: 50% 50%;
  animation: spinanimation 0.793651s linear 0s infinite normal forwards;
}
@keyframes spinanimation {
  0% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.5856, 0.0703, 0.4143, 0.9297);
  }
  100% {
    transform: rotate(360deg);
  }
}
