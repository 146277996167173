@media (min-width: 768px) {
  .customModal {
    max-width: 696px;
  }
}
.customModalHeader {
  border: none;
  padding: 18px 20px 0;
}
.customModalFooter {
  border: none;
}
.customModalBody {
  padding: 0;
}
.modalBodyContainer {
  padding: 51px 75px 68px;
}
.modalTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 130.9%;
  text-align: center;
  color: var(--secondaryColor1);
  margin: 30px 0 25px;
}
.modalContent {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 148.9%;
  text-align: center;
  color: var(--lightGray);
  margin: 0;
}
@media (max-width: 767px) {
  .modalBodyContainer {
    padding: 17px 45px 68px;
  }
  .modalTitle {
    font-size: 26px;
  }
  .modalImage {
    width: 110px;
  }
}
@media (max-width: 480px) {
  .modalBodyContainer {
    padding: 17px 25px 68px;
  }
}
