.customModalBody {
  padding: 0;
}
@media (min-width: 620px) {
  .customPublishModal {
    width: 600px;
    max-width: 600px;
  }
}
@media (min-width: 730px) {
  .customPublishModal {
    width: 700px;
    max-width: 700px;
  }
}
.modalCloseButtonContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 28px;
  padding-right: 25px;
  margin-bottom: 9px;
}
.modalCloseButton {
  outline: none !important;
  box-shadow: none !important;
  color: var(--lightGray);
  font-weight: lighter;
  cursor: pointer;
}
.modalBodyContainer {
  padding: 0 55px;
}
.modalTitle {
  color: #000;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: 106.9%; /* 31.001px */
  text-align: center;
  margin-bottom: 60px;
}
.description {
  color: var(--lightGray);
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 149.9%; /* 28.481px */
  letter-spacing: 0.19px;
  margin-bottom: 6px;
}
.declineReasonInput {
  border-radius: 5px;
  border: 2px solid var(--secondaryColor4);
  width: 100%;
  height: 108px;
  margin-bottom: 50px;
  outline: none !important;
  padding: 10px;
}
.buttonContainer {
  display: flex;
  flex-direction: row;
}
.declineButton {
  height: 60px;
  width: 230px;
  border: none;
  border-radius: 60px;
  margin-bottom: 59px;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  text-transform: capitalize;
  color: #000;
  font-weight: 400;
}
.declineButton {
  background-color: white;
  border: 1px solid red;
  color: red;
}
@media (max-width: 620px) {
  .buttonContainer {
    width: 100%;
    flex-direction: column;
  }
  .declineButton {
    width: 100%;
  }
}
@media (max-width: 460px) {
  .modalBodyContainer {
    padding: 0 25px;
  }
}
