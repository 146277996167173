.itemContainer {
  margin-bottom: 24px;
}
.title {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 148.9%;
  color: #000000;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.outerContainer {
  width: 100%;
  background-color: var(--secondaryColor4);
  height: 7.24px;
  border-radius: 66px;
}
.innerContainer {
  height: 100%;
  background-color: var(--secondaryColor1);
  border-radius: 66px;
}
