.moreIcon {
  width: 38px;
  height: 38px;
  background-color: var(--primaryColor2);
  display: flex;
  color: white;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .listBarContainer {
    margin: 0 -4px;
    height: 27px;
    overflow: hidden;
  }
}
@media (max-width: 991px) {
  .moreIconContaier {
    padding-left: 0;
  }
}
